define(['jquery'], function($) {
  
  return {
    /**
     * Initiation function
     * @param config
     */
    init: function(config) {
      // Check values and set defaults if necessary
      config.$globalTabs ? true : config.$globalTabs = '',
      config.tabContainer ? true : config.tabContainer = '',
      config.tabHeading ? true : config.tabHeading = '',
      config.tabBody ? true : config.tabBody = '',
      config.speed ? true : config.speed = 0;
      // Initiate click events
      this.tabStart(config);
    },

    /**
     * Dynamically set first tab and initiate click events
     * @param config
     */
    tabStart: function(config) {
      config.$globalTabs.each(function() {
        $(this).find(config.tabBody).hide().first().show();
        $(this).find(config.tabHeading).first().addClass('active');
      });
      this.tabChartClick(config);
    },

    /**
     * Set clicked tab to display and set rest to hide
     * @param config
     */
    tabChartClick: function(config) {
      $(config.tabHeading).on('click', function() {
        if (!$(this).hasClass('active')) {
          $(this).closest(config.tabContainer).find(config.tabHeading).removeClass('active');
          $(this).closest(config.tabContainer).find(config.tabBody).hide();
          $(this).addClass('active').parent().find(config.tabBody).stop().fadeIn(config.speed);
        }
      });
    }
  };
});
